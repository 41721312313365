export const bookList = [
  {
    cardType: 'book',
    data: {
      image:
        'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696527181/qd6wciaqpwehlvd8etwj.jpg',
      id: 1,
      name: 'Civics for Class 10th',
      author: 'NCERT',
      description:
        'Students learn about the Indian political system, its institutions, and the role of citizens in a democracy',
      rating: '4.4',
      rate: 90,
    },
  },
  {
    cardType: 'book',
    data: {
      image:
        'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696567230/Brown_Rusty_Mystery_Novel_Book_Cover_anmtzn.jpg',
      id: 2,
      name: 'Solider',
      author: 'By Oivia Wilson',
      description:
        'A soldier is a protagonist in a book who embarks on a perilous journey of duty, honor, and sacrifice in the face of adversity',
      rating: '4.9',
      rate: 400,
    },
  },
  {
    cardType: 'book',
    data: {
      image:
        'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696567227/Black_and_Yellow_Modern_Two_Side_Book_Cover_cib0jl.jpg',
      id: 3,
      name: 'Two Side',
      author: 'By Pedro Fernandes',
      description:
        'The book Two Sides delves into the complex lives of two protagonists, offering a dual perspective on their intertwined destinies',
      rating: '4.9',
      rate: 400,
    },
  },
  {
    cardType: 'book',
    data: {
      image:
        'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696567230/Brown_Rusty_Mystery_Novel_Book_Cover_2_seesru.jpg',
      id: 4,
      name: 'Notebook',
      author: 'Rachelle Beaudry',
      description:
        'The book Notebook unfolds a poignant love story through the pages of a cherished diary, revealing timeless emotions and memories',
      rating: '4.0',
      rate: 360,
    },
  },
  {
    cardType: 'book',
    data: {
      image:
        'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696567230/Modern_Bali_Book_Cover_igqd8v.jpg',
      id: 5,
      name: 'Bali',
      author: 'Jhon Rae',
      description:
        'Bali: A Journey to Paradise takes readers on a vivid exploration of the enchanting Indonesian island, blending rich cultural insights with its natural beauty.',
      rating: '3.7',
      rate: 320,
    },
  },
  {
    cardType: 'book',
    data: {
      image:
        'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696567229/Watercolor_Love_Story_Book_Cover_yywdax.jpg',
      id: 6,
      name: 'Love Story',
      author: 'Olivia Wilson',
      description:
        'Love Story is a timeless novel that weaves a heartfelt and poignant tale of romance transcending social boundaries.',
      rating: '3.8',
      rate: 420,
    },
  },
  {
    cardType: 'book',
    data: {
      image:
        'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696567228/Light_Turquoise_Green_Brown_Vibrant_Tree_Illustration_Book_Cover_bv3scg.jpg',
      id: 7,
      name: 'The Story Of The Tree',
      author: 'Helene Paquet',
      description:
        'The Story of the Tree narrates the life journey of a single tree, unveiling profound lessons about growth, resilience, and interconnectedness in nature.',
      rating: '3.0',
      rate: 620,
    },
  },
  {
    cardType: 'book',
    data: {
      image:
        'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696567228/Scary_darkness_minimalist_horror_novel_book_cover_vclcob.jpg',
      id: 8,
      name: 'The Grim Reaper',
      author: 'Henreitta Mitchell',
      description:
        'The Grim Reaper is a chilling thriller that follows a relentless detective pursuit of a cunning serial killer with a deadly obsession.',
      rating: '2.9',
      rate: 450,
    },
  },
  {
    cardType: 'book',
    data: {
      image:
        'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696567228/Brown_Orange_Vivid_Pirate_Ship_Illustration_Book_Cover_qcdhp4.jpg',
      id: 9,
      name: 'Ocean',
      author: 'Aaron Loeb',
      description:
        'Ocean" is a mesmerizing photographic journey that captures the stunning beauty and diverse life forms of Earths vast and mysterious oceans.',
      rating: '4.6',
      rate: 500,
    },
  },
  {
    cardType: 'book',
    data: {
      image:
        'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696567227/Black_Minimalist_Dark_Floral_Photo_Book_Cover_nzum1y.jpg',
      id: 10,
      name: 'The Never Garder',
      author: 'Helene Paquet',
      description:
        'The Never Garden" is a gripping fantasy novel that transports readers to an otherworldly realm',
      rating: '4.7',
      rate: 530,
    },
  },
];
