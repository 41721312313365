import React from 'react';
import HomeCarousel from '../customer/Components/Carousel/HomeCarousel';
import { homeCarouselData } from '../customer/Components/Carousel/HomeCaroselData';
import HomeProductSection from '../customer/Components/Home/HomeProductSection';
import { sareePage1 } from '../Data/Saree/page1';
import { dressPage1 } from '../Data/dress/page1';
import { gounsPage1 } from '../Data/Gouns/gouns';
import { kurtaPage1 } from '../Data/Kurta/kurta';
import { mensShoesPage1 } from '../Data/shoes';
import { mens_kurta } from '../Data/Men/men_kurta';
import { lengha_page1 } from '../Data/Women/LenghaCholi';

import { bookList } from '../Data/books';
import ProductCard from '../customer/Components/Product/ProductCard/ProductCard';

const Homepage = () => {
  return (
    <div className=''>
      <div className='flex justify-center bg-red-400'>
        <HomeCarousel images={homeCarouselData} />
      </div>

      <div className='py-20 space-y-10 '>
        <h2 className='text-4xl font-extrabold text-gray-900 sm:text-2xl px-7'>
          Popular
        </h2>

        <div className='flex flex-wrap justify-center sm:justify-between'>
          {bookList.map((book) => (
            <ProductCard key={book.data.id} product={book} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Homepage;
