import React from 'react';
import './ProductCard.css';
import { useLocation, useNavigate } from 'react-router-dom';
import StarHalfIcon from '@mui/icons-material/StarHalf';

const ProductCard = ({ product }) => {
  console.log(product);
  const { name, author, image, rate, rating } = product.data;
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/product/${product?.data?.id}`);
  };

  return (
    <div
      onClick={handleNavigate}
      className='productCard w-[15rem] border m-3 transition-all cursor-pointer '
    >
      <div className='h-[20rem]'>
        <img
          className='object-cover object-left-top w-full h-full'
          src={image}
          alt=''
        />
      </div>
      <div className='p-3 bg-white textPart '>
        <div>
          <p className='font-bold opacity-60'>{author}</p>
          <p className=''>{name}</p>

          <p className='flex items-center gap-1 font-semibold opacity-50'>
            <StarHalfIcon color='warning' />
            {rating}
          </p>
        </div>

        <div className='flex items-center space-x-2'>
          <p className='font-semibold'>₹{rate - rate * (10 / 100)}</p>
          <p className='font-semibold line-through opacity-50'>{rate}</p>
          <p className='font-semibold text-green-600'>{10}% off</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
