import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { homeCarouselData } from './HomeCaroselData';
import { useNavigate } from 'react-router-dom';

const handleDragStart = (e) => e.preventDefault();
// import bannner from './banner.';
const HomeCarousel = () => {
  const navigate = useNavigate();
  const item = homeCarouselData.map((item) => (
    <img
      className='mx-auto cursor-pointer'
      // onClick={() => navigate(item.path)}
      src={item.path}
      alt='carousel'
      onDragStart={handleDragStart}
      role='presentation'
    />
  ));
  return (
    <AliceCarousel
      mouseTracking
      items={item}
      autoPlay
      infinite
      autoPlayInterval={2000}
      disableButtonsControls
    />
  );
};

export default HomeCarousel;
