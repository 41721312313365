export const homeCarouselData = [
  {
    image: `https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696703954/xaj5mdqmu8pswxnnkgga.jpg`,
    path: 'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696703954/xaj5mdqmu8pswxnnkgga.jpg',
  },
  {
    image: `https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696703954/zfl01f0nv6n3xjdxi6lz.jpg`,
    path: 'https://res.cloudinary.com/dtlyzn2yz/image/upload/v1696703954/zfl01f0nv6n3xjdxi6lz.jpg',
  },
];
