export const navigation = {
  categories: [
    {
      id: 'categories',
      name: 'Categories',
      featured: [
        // {
        //   name: 'New Arrivals',
        //   href: '/',
        //   imageSrc:
        //     'https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg',
        //   imageAlt:
        //     'Models sitting back to back, wearing Basic Tee in black and bone.',
        // },
        // {
        //   name: 'Basic Tees',
        //   href: '/',
        //   imageSrc:
        //     'https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg',
        //   imageAlt:
        //     'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
        // },
      ],
      sections: [
        {
          id: 'competitiveExams',
          name: 'Competitive Exams',
          items: [
            // { name: 'Tops', id: 'top', href: `{women/clothing/tops}` },
            // { name: 'Dresses', id: 'women_dress', href: '#' },
            // { name: 'Women Jeans', id: 'women_jeans' },
            // { name: 'Lengha Choli', id: 'lengha_choli' },
            // { name: 'Sweaters', id: 'sweater' },
            // { name: 'T-Shirts', id: 't-shirt' },
            // { name: 'Jackets', id: 'jacket' },
            // { name: 'Gouns', id: 'gouns' },
            // { name: 'Sarees', id: 'saree' },
            // { name: 'Kurtas', id: 'kurtas' },
          ],
        },
        {
          id: 'higherEducation',
          name: 'Higher Education',
          items: [
            // { name: 'Watches', id: 'watch' },
            // { name: 'Wallets', id: 'wallet' },
            // { name: 'Bags', id: 'bag' },
            // { name: 'Sunglasses', id: 'sunglasse' },
            // { name: 'Hats', id: 'hat' },
            // { name: 'Belts', id: 'belt' },
          ],
        },
        {
          id: 'schoolBooks',
          name: 'School Books',
          items: [
            // { name: 'Full Nelson', id: '#' },
            // { name: 'My Way', id: '#' },
            // { name: 'Re-Arranged', id: '#' },
            // { name: 'Counterfeit', id: '#' },
            // { name: 'Significant Other', id: '#' },
          ],
        },
        {
          id: 'literature',
          name: 'Literature',
          items: [
            // { name: 'Full Nelson', id: '#' },
            // { name: 'My Way', id: '#' },
            // { name: 'Re-Arranged', id: '#' },
            // { name: 'Counterfeit', id: '#' },
            // { name: 'Significant Other', id: '#' },
          ],
        },
        {
          id: 'kashmiri',
          name: 'Kashmir',
          items: [
            // { name: 'Full Nelson', id: '#' },
            // { name: 'My Way', id: '#' },
            // { name: 'Re-Arranged', id: '#' },
            // { name: 'Counterfeit', id: '#' },
            // { name: 'Significant Other', id: '#' },
          ],
        },
        {
          id: 'religion',
          name: 'Religion',
          items: [
            // { name: 'Full Nelson', id: '#' },
            // { name: 'My Way', id: '#' },
            // { name: 'Re-Arranged', id: '#' },
            // { name: 'Counterfeit', id: '#' },
            // { name: 'Significant Other', id: '#' },
          ],
        },
      ],
    },
  ],
  pages: [
    { name: 'About Us', id: '/' },
    { name: 'Contact', id: '/' },
  ],
};
